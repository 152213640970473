@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.modal-carousel {
    display: none;
    position: fixed;
    z-index: 5;
    left: 0;
    top: 50px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    margin-top: 150px;
    padding: 20px;
    border: 1px solid #888;
    width: 450px;

    .title-product {
        text-decoration: none;

        p {
            text-align: center;
            font: normal normal 700 18px Poppins;
            color: #064C37;
            margin-bottom: 20px;
        }
    }

    .quantity {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 15px;

        .quantity-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .title-quantity {
                h2 {
                    font: normal normal 700 14px Poppins;
                    color: #064C37;
                    text-transform: uppercase;
                }
            }

            .input-quantity {

                background: #F7F7F7 0% 0% no-repeat padding-box;
                border-radius: 34px;
                padding: 5px 15px;

                button {
                    all: unset;
                    cursor: pointer;


                    .cercle {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-color: #064C37;

                        img {
                            height: 10px;
                            width: 10px;
                        }
                    }
                }

                input {
                    background: #FFFFFF;
                    border: 1px solid #707070;
                    border-radius: 15px;
                    width: 55px;
                    height: 28px;
                    margin: 0px 10px;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 1.05px;
                    color: #000000;
                    text-transform: uppercase;
                }

                input::placeholder {
                    color: #000000;
                }
            }

            .input-quantity input::-webkit-inner-spin-button,
            .input-quantity input::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }


        }

        .add-cart-content {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #915833;
            border-radius: 10px;
            padding: 1px 5px;

            .article-add-cart-modal {
                all: unset;
                cursor: pointer;


                .add-cart {
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        margin-right: 5px;
                        height: 35px;
                        width: 35px;
                    }

                    p {
                        font: normal normal normal 13px Poppins;
                        letter-spacing: 1px;
                        color: #FFFFFF;
                        text-transform: uppercase;
                        margin-right: 2px;
                    }
                }

            }
        }


    }
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.article-add-cart {
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}


@media (max-width: 500px) {
    .quantity {
        flex-direction: column !important;
        align-items: center !important;
    }
}



@media (max-width: 768px) {

    .modal-content {
        margin-top: 150px;
        width: 250px !important;
    }

    .quantity {
        flex-direction: column !important;
        align-items: center !important;
    }
}